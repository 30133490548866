import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  fetchCurrentEvent,
  fetchEventByID,
  fetchCurrentEventPreview,
  fetchSpeakers,
  fetchQuestions,
  postQuestion,
  postLikeQuestion,
  deleteLikeQuestion,
} from ':api';

import {
  setModalVisibility,
  setTooltipVisibility,
  setQuestions,
  likeQuestion as likeQuestionAction,
  undoLikeQuestion as undoLikeQuestionAction,
} from './index';

/**
 * @description Gets current event
 */
export const getCurrentEvent = createAsyncThunk('event/getCurrentEvent', async () => {
  try {
    return await fetchCurrentEvent();
  } catch (e) {
    return null;
  }
});

/**
 * @description Gets event by ID
 */
export const getEventByID = createAsyncThunk('event/getEventByID', async (id: string) => {
  try {
    return await fetchEventByID(id);
  } catch (e) {
    return null;
  }
});

/**
 * @description Gets current event preview for non-authorised user
 */
export const getCurrentEventPreview = createAsyncThunk('event/getCurrentEventPreview', async () => {
  try {
    return await fetchCurrentEventPreview();
  } catch (e) {
    return null;
  }
});

/**
 * @description Gets speakers of event
 */
export const getEventSpeakers = createAsyncThunk('event/getCurrentEventSpeakers', async (eventId: number) => {
  try {
    return await fetchSpeakers(eventId);
  } catch (e) {
    return null;
  }
});

/**
 * @description Gets speakers questions every 20 seconds
 */
export const watchingQuestions = createAsyncThunk('event/watchingQuestions', async (eventId: number, thunksAPI) => {
  try {
    const questions = await fetchQuestions(eventId);
    thunksAPI.dispatch(setQuestions(questions));

    const intervalID = setInterval(async () => {
      if (thunksAPI.signal.aborted) clearInterval(intervalID);

      const questions = await fetchQuestions(eventId);
      thunksAPI.dispatch(setQuestions(questions));
    }, 20000);
  } catch (e) {
    // TODO Show error
  }
});

type CreateQuestionPayload = {
  eventId: number;
  isAnonymous: boolean;
  message: string;
  speakerId: number | null;
};
/**
 * @description Сreate a new speaker question
 */
export const createEventQuestion = createAsyncThunk<void, CreateQuestionPayload>(
  'event/createEventQuestion',
  async ({ eventId, speakerId, message, isAnonymous }, thunksAPI) => {
    try {
      const statusCode = await postQuestion(eventId, speakerId, message, isAnonymous);
      thunksAPI.dispatch(setModalVisibility(false));

      if (statusCode === 201) {
        thunksAPI.dispatch(setTooltipVisibility(true));

        setTimeout(() => thunksAPI.dispatch(setTooltipVisibility(false)), 5000);
      } else {
        // TODO Show error tooltip
      }
    } catch (e) {
      // TODO Show error tooltip
    }
  }
);

/**
 * @description Like speaker question
 */
export const likeQuestion = createAsyncThunk('event/likeQuestion', async (questionId: number, thunksAPI) => {
  thunksAPI.dispatch(likeQuestionAction(questionId));

  try {
    const statusCode = await postLikeQuestion(questionId);

    if (statusCode !== 200) {
      thunksAPI.dispatch(undoLikeQuestionAction(questionId));
      // TODO Show error
    }
  } catch (e) {
    thunksAPI.dispatch(undoLikeQuestionAction(questionId));
    // TODO Show error
  }
});

/**
 * @description Undo like of speaker question
 */
export const undoLikeQuestion = createAsyncThunk('event/undoLikeQuestion', async (questionId: number, thunksAPI) => {
  thunksAPI.dispatch(undoLikeQuestionAction(questionId));

  try {
    const statusCode = await deleteLikeQuestion(questionId);
    if (statusCode !== 200) {
      thunksAPI.dispatch(likeQuestionAction(questionId));
      // TODO Show error
    }
  } catch (e) {
    thunksAPI.dispatch(likeQuestionAction(questionId));
    // TODO Show error
  }
});
