import VKITAuth from '@beeline/lk-auth';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { store } from ':store';

import { logIn } from ':store/auth';

const { REACT_APP_LK_AUTH_SERVER_URL, REACT_APP_LK_API_SERVER_URL: API_URL } = process.env;

export const auth = new VKITAuth(REACT_APP_LK_AUTH_SERVER_URL);

export const API = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

let refreshInProgress = false;

/**
 * @description Default BU request interceptor
 * @param {AxiosRequestConfig} request Intercepted request
 * @returns {AxiosRequestConfig} Users request
 */
const defaultRequestInterceptor = async (request: AxiosRequestConfig) => {
  const { headers } = request;

  if (headers) {
    headers.Authorization = `Bearer ${auth.getAccessToken()}`;
  }

  return request;
};

/**
 * @description Called when refresh is in progress
 * @param {AxiosError} err Request error to work with
 */
const onRefreshInProgress = (err: AxiosError) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const accessToken = auth.getAccessToken();
      if (accessToken && err.config.headers) {
        err.config.headers.Authorization = `Bearer ${accessToken}`;
        axios.request(err.config).then(resolve).catch(reject);
        return;
      }

      reject(new Error('Access token not defined'));
    }, 500);
  });

/**
 * @description Called when refresh is required
 * @param {AxiosError} err Request error to work with
 * @returns {Promise<AxiosResponse | AxiosError>} Second request data
 */
const onRefreshRequired = async (err: AxiosError) => {
  try {
    const { access_token } = await auth.refresh();
    refreshInProgress = false;
    if (err.config.headers) {
      err.config.headers.Authorization = `Bearer ${access_token}`;
    }
    return axios.request(err.config);
  } catch (err) {
    refreshInProgress = false;
    auth.clean();
    store.dispatch(logIn());
    return err;
  }
};

/**
 * @description Default BU error request interceptor
 * @param {AxiosError} err Intercepted error
 * @returns {Promise<AxiosError>} Promise with axios error
 */
export const errorRequestInterceptor = async (err: AxiosError) => {
  if (err.response) {
    const { status } = err.response;

    if (status === 401 && !refreshInProgress) {
      const refreshToken = auth.getRefreshToken();
      if (refreshToken) {
        refreshInProgress = true;
        return onRefreshRequired(err);
      }

      refreshInProgress = false;
      auth.clean();
    } else if (status === 401 && refreshInProgress) {
      return onRefreshInProgress(err);
    }
    return Promise.reject(err);
  }
  return err;
};

API.interceptors.request.use(defaultRequestInterceptor);
API.interceptors.response.use(undefined, errorRequestInterceptor);
