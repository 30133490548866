export enum ODBackendEventStatus {
  ACTIVE = 'active',
  ARCHIVE = 'archive',
  DRAFT = 'draft',
  PLANNED = 'planned',
}

export interface ODBackendQuestioner {
  id: number;
  last_name?: string;
  name: string;
  photo: string | null;
}

export interface ODBackendParticipant {
  custom_name?: string;
  fio: string;
  photo: string | null;
  position?: string;
}

export interface ODBackendSpeaker extends ODBackendParticipant {
  id: number;
}

export interface ODBackendEventPublic {
  banner_auth?: string;
  event_id: number;
  name: string;
  start_date: string | null;
  zoom_link: string | null;
}

export interface ODBackendEventTopic {
  description?: string;
  name: string;
  speaker: ODBackendSpeaker[];
  time_end: string;
  time_start: string;
}
/**
 * Announced and archived items
 */
export interface ODBackendEventDescription {
  date: string;
  description: string;
  event_id: number;
  image: string;
  link?: string;
  name: string;
  status: string;
}
export interface ODBackendQuestion {
  author?: ODBackendQuestioner;
  count_like: number;
  create_date: string;
  id: number;
  name: string;
  speaker?: ODBackendSpeaker;
  user_like: boolean;
}

export interface ODBackendEvent {
  announcement: string;
  archive_link: string;
  ask_question: boolean;
  banner: string;
  banner_mobile: string;
  event_description: string | null;
  event_id: number;
  event_start_button: {
    color: 'black' | 'yellow';
    position: 'left' | 'middle' | 'right';
  };
  moderator: ODBackendParticipant[];
  name: string;
  program_description: string;
  program_name: string;
  rocket_chat_link: string | null;
  start_date: string | null;
  status: ODBackendEventStatus;
  topic: ODBackendEventTopic[];
  zoom_link: string | null;
}

export interface ODBackendTranslation {
  player_link: string;
  player_type: number;
  translation_url: string;
}
