import { FC, useEffect, useState, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Icons, Snackbar } from '@beeline/lk-ui';

import {
  BubbleInfo,
  EventCard,
  Modal,
  Page,
  Program,
  Skeleton,
  ChatButton,
  EventPopup,
  AskQuestionBanner,
} from ':components';
import { RootState } from ':store';
import { ODBackendEventStatus } from ':types';

import styles from './styles.module.scss';

import { getTranslation } from ':store/auth';
import { getCurrentEvent, getEventByID } from ':store/event';

/**
 * @description AboutPage component
 * @returns {ReactNode} Rendered content
 */
export const Event: FC = () => {
  const dispatch = useDispatch();
  const {
    auth: { translation_url, playerType }, // TODO why auth state?
    event: { current },
  } = useSelector((state: RootState) => state);
  const [modalOpened, setModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [showError, setErrorVisibility] = useState(false);

  const { id } = useParams();

  const [showQuestions, setVisibleQuestions] = useState(false);
  const openQuestions = () => setVisibleQuestions(true);
  const closeQuestions = () => setVisibleQuestions(false);

  useEffect(() => {
    dispatch(id ? getEventByID(id) : getCurrentEvent());
  }, [dispatch, id]);

  useEffect(() => {
    if (translation_url && modalOpened) {
      setLoad(true);
    }
  }, [translation_url, modalOpened]);

  /**
   * @description Opens modal and requests translation
   */
  const onOpenClick = () => {
    if (current?.event_id) {
      dispatch(getTranslation({ id: current.event_id, modal: setModal, loading: setLoad, error: setErrorVisibility })); // TODO revise this
    }
  };

  /**
   * @description Closes modal
   */
  const onModalClose = () => {
    setModal(false);
    setLoad(false);
  };

  if (!current) {
    return (
      <Page className={styles.about}>
        <Skeleton.About />
      </Page>
    );
  }

  const {
    ask_question,
    announcement,
    status,
    archive_link,
    event_description,
    event_start_button,
    banner,
    banner_mobile,
  } = current;

  const enabledQuestions = ask_question && status !== ODBackendEventStatus.ARCHIVE;
  const showQuestionsButton = enabledQuestions && !modalOpened;
  const isPlannedEvent = status === ODBackendEventStatus.PLANNED;
  const isArchivedEvent = status === ODBackendEventStatus.ARCHIVE;
  const playerURL = isArchivedEvent ? archive_link : translation_url;
  const showAskQuestionBanner = enabledQuestions && announcement;

  const questions = showQuestions ? (
    <EventPopup onClose={closeQuestions} />
  ) : (
    <ChatButton iconName={Icons.Question} onClick={openQuestions} title="Вопросы спикерам" />
  );

  const eventDescription = (
    <>
      <EventCard
        buttonAlign={event_start_button?.position || 'middle'}
        buttonColor={event_start_button?.color || 'black'}
        disabled={load}
        hide={isPlannedEvent}
        image={banner}
        imageMobile={banner_mobile}
        onOpenClick={onOpenClick}
        title={isArchivedEvent ? 'Смотреть запись' : 'Открыть трансляцию'}
      />
      {event_description && <BubbleInfo htmlDescription={event_description} />}
      {showAskQuestionBanner && <AskQuestionBanner htmlDescription={announcement} openQuestions={openQuestions} />}
      <Program event={current} />
      {showQuestionsButton && questions}
    </>
  );

  return (
    <Page className={styles.about}>
      <Modal
        audioPlayerBackground={current.banner}
        onClose={onModalClose}
        open={modalOpened}
        playerType={playerType}
        playerURL={playerURL}
      />
      {eventDescription}
      <Snackbar
        horizontal="center"
        message="Что-то пошло не так, пожалуйста, обратитесь в helpdesk."
        open={showError}
      />
    </Page>
  );
};
